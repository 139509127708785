<template>
    <div id="app">
        <header>
            <a href="https://lofthall.ru/" class="desktop-icon"
                ><img src="/static/img/logo.svg" alt=""
            /></a>
            <div class="text-info">
                <a href="tel:+79852002121">
                    +7 985 200-21-21
                </a>
                <a href="https://lofthall.ru/" class="mobile-icon">
                    <img src="/static/img/logo.svg" alt="" />
                </a>
                <a href="mailto:info@c11p.ru">
                    info@c11p.ru
                </a>
            </div>
        </header>
        <main>
            <div class="get-pay">
                <p>
                    Безопасная онлайн оплата услуг &nbsp;
                    <span>
                        через <img src="/static/img/sber-logo.svg" alt="" />
                    </span>
                </p>
                <h1>
                    Онлайн оплата
                </h1>
                <button :disabled="!offer" @click="pay" class="pay">
                    Оплатить
                </button>
                <label>
                    <input v-model="offer" type="checkbox" />
                    <div class="custom-checkbox"></div>
                    Я принимаю условия
                    <button @click="openModal = true">
                        договора оферты
                    </button>
                </label>
            </div>
            <div class="hrefs">
                <h2>Что мы предлагаем</h2>
                <div class="href-container">
                    <div>
                        <p>Аренда залов</p>
                        <img src="/static/img/1.jpg" alt="" />
                    </div>
                    <div>
                        <p>Кухня</p>
                        <img src="/static/img/2.jpg" alt="" />
                    </div>
                    <div>
                        <p>Обслуживание</p>
                        <img src="/static/img/3.jpg" alt="" />
                    </div>
                    <div>
                        <p>Оборудование</p>
                        <img src="/static/img/4.jpg" alt="" />
                    </div>
                    <div>
                        <p>О нас</p>
                        <img src="/static/img/5.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div class="pay-info">
                <h2>об оплате</h2>
                <div class="info-container">
                    <img src="/static/img/sber.svg" alt="" />
                    <p>
                        Для оплаты (ввода реквизитов Вашей карты) Вы будете
                        перенаправлены на платёжный шлюз ПАО СБЕРБАНК. <br />
                        <br />
                        Соединение с платёжным шлюзом и передача информации
                        осуществляется в защищённом режиме с использованием
                        протокола шифрования SSL. В случае если Ваш банк
                        поддерживает технологию безопасного проведения
                        интернет-платежей Veriﬁed By Visa, MasterCard
                        SecureCode, MIR Accept, J-Secure для проведения платежа
                        также может потребоваться ввод специального пароля.
                        Настоящий сайт поддерживает 256-битное шифрование.
                        Конфиденциальность сообщаемой персональной информации
                        обеспечивается ПАО СБЕРБАНК. <br />
                        <br />
                        Введённая информация не будет предоставлена третьим
                        лицам за исключением случаев, предусмотренных
                        законодательством РФ. <br />
                        <br />
                        Проведение платежей по банковским картам осуществляется
                        в строгом соответствии с требованиями платёжных систем
                        МИР, Visa Int., MasterCard Europe Sprl, JCB.
                    </p>
                    <div class="pay-imgs">
                        <img src="/static/img/visa.svg" alt="" />
                        <img src="/static/img/mastercard.svg" alt="" />
                        <img src="/static/img/Maestro.svg" alt="" />
                        <img src="/static/img/mir.svg" alt="" />
                    </div>
                </div>
            </div>
            <transition name="fade">
                <div class="offer-modal" v-if="openModal">
                    <div @click="openModal = false" class="shadow-box"></div>
                    <div class="offer-content">
                        <img
                            @click="openModal = false"
                            src="/static/img/close.svg"
                            alt="закрыть модальное окно"
                        />
                        <pdf
                            v-for="p in numPages"
                            :key="p"
                            :src="pdf"
                            :page="p"
                        ></pdf>
                    </div>
                </div>
            </transition>
        </main>
        <footer>
            <p>
                ООО «КЛЕВЕНТС ЭЛЕВЕН ПРОДАКШН», ИНН 9725025256, 115280, г.
                Москва, вн. тер. г. муниципальный округ <br />
                Даниловский, ул. Ленинская слобода, дом 26, помещ. XXXII-66,
                ком. 2
            </p>
            <p>
                <a href="/static/pltck.pdf">
                    Политика конфиденциальности
                </a>
                <a href="/static/user.pdf">
                    Пользовательское соглашение
                </a>
            </p>
        </footer>
    </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
    name: "App",
    components: {
        pdf,
    },
    data() {
        return {
            payData: {
                amount: 0,
                currency: "RUB",
                order_number: "",
                description: "",
            },
            openModal: false,
            offer: false,
            pdf: null,
            numPages: 0,
        };
    },
    methods: {
        pay() {
            ipayCheckout(
                this.payData,
                function(order) {
                    showSuccessfulPurchase(order);
                },
                function(order) {
                    showFailurefulPurchase(order);
                }
            );
        },
    },
    mounted() {
        new IPAY({ api_token: "gcuhk1nek45dif2apmqfqikif8" });
        fetch("/api/bill").then((res) => {
            res.json().then((response) => {
                this.payData = {
                    amount: response.amount,
                    currency: response.currency,
                    order_number: response.orderNumber,
                    description: response.description,
                };
                this.pdf = response.contractUrl;
                pdf.createLoadingTask(response.contractUrl).promise.then(
                    (pdf) => {
                        this.numPages = pdf.numPages;
                    }
                );
            });
        });
    },
};
</script>

<style lang="scss">
@import "./fonts.scss";
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
$size-base: 16px;

@function ptr($size) {
    @return #{$size / $size-base}rem;
}

$text-color: black;

$main-font: "Montserrat", sans-serif;

html {
    font-size: 0.8333333333vw;
    background: white;
}

.desktop-icon > img {
    width: ptr(70px);
    height: ptr(70px);
    filter: invert(1);
}

@media (max-width: 900px) {
    html {
        font-size: 5vw;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    font-family: $main-font;
    color: $text-color;
    &:focus {
        outline: none;
    }
    &::selection {
        background: #37b04c;
    }
}
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    padding: ptr(32px) ptr(44px);
    width: 100%;
    .desktop-icon {
        width: ptr(59px);
        height: ptr(59px);
    }
}
.text-info {
    font-size: ptr(14px);
    display: flex;
    a {
        text-decoration: none;
        font-size: ptr(14px);
    }
    a:nth-child(3) {
        margin-left: ptr(35px);
    }
}
.mobile-icon {
    display: none;
    filter: invert(1);
}
.get-pay {
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .pay {
        margin-top: ptr(50px);
        border-radius: ptr(500px);
        border: none;
        background: linear-gradient(34.53deg, #37b04c 10.26%, #49b69d 97.54%);
        width: ptr(282px);
        height: ptr(62px);
        font-size: ptr(18px);
        font-weight: 500;
        cursor: pointer;
        padding: 0;
        box-shadow: 0 ptr(5px) ptr(30px) 0 #37b04b00;
        transition: 0.3s ease-in;
        color: white;
        &:hover {
            box-shadow: 0 ptr(2px) ptr(30px) 0 #37b04b67;
        }
    }
    p {
        font-size: ptr(14px);
        margin-bottom: ptr(35px);
        img {
            width: ptr(58px);
            display: inline-block;
            margin-left: ptr(10px);
        }
    }
    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    label {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: ptr(12px);
        margin-top: ptr(20px);
        input {
            opacity: 0;
            height: 0;
            width: 0;
            position: absolute;
            &:checked + .custom-checkbox {
                background: white;
                &::after {
                    opacity: 1;
                }
            }
        }
        button {
            border: none;
            height: auto;
            background: none;
            position: relative;
            margin-left: ptr(4px);
            cursor: pointer;
            padding: 0;
            &::after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: white;
            }
        }
        a {
            text-decoration: none;
        }
        .custom-checkbox {
            width: ptr(15px);
            height: ptr(15px);
            border-radius: ptr(2px);
            border: ptr(2px) solid black;
            margin-right: ptr(7.5px);
            transition: 0.2s ease-in-out;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
                content: "";
                background-image: url("/static/img/checking-mark.svg");
                width: ptr(10px);
                height: ptr(10px);
                position: absolute;
                opacity: 0;
                transition: 0.2s ease-in-out;
            }
        }
    }
}
h1 {
    font-family: $main-font;
    font-weight: 400;
    font-size: ptr(74px);
    text-align: center;
}
.pay-info {
    margin-top: ptr(121px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
h2 {
    margin-bottom: ptr(73px);
    font-weight: 700;
    text-transform: uppercase;
    font-size: ptr(27px);
}
.hrefs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ptr(179px) 0 ptr(223px);
}
.href-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: ptr(20px);
    div {
        border-radius: 50%;
        width: ptr(257px);
        height: ptr(257px);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
            border-radius: 50%;
            width: 100%;
        }
        p {
            font-weight: 600;
            font-size: ptr(13px);
            text-transform: uppercase;
            text-decoration: none;
            position: absolute;
            color: white;
        }
    }
}
.info-container > img {
    filter: invert(1);
}
.info-container {
    width: ptr(599px);
    height: ptr(878px);
    box-sizing: border-box;
    border: ptr(1px) solid black;
    border-radius: ptr(30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ptr(48px) 0 ptr(84px);
    img {
        width: ptr(42px);
        height: ptr(50px);
        margin-bottom: ptr(50px);
    }
    p {
        font-size: ptr(16px);
        line-height: ptr(28px);
        width: ptr(523px);
    }
}
footer {
    margin: ptr(102px) 0;
    p {
        text-align: center;
        font-size: ptr(12px);
        color: #707070;
        margin-top: ptr(20px);
        a {
            color: #707070;
            text-decoration: none;
        }
    }
}
.mobile {
    display: none;
}
.offer-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.pay-imgs {
    display: grid;
    grid-auto-flow: column;
    gap: ptr(20px);
    margin-top: ptr(40px);
    img {
        width: ptr(60px);
    }
}
.shadow-box {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
}
.offer-content {
    background: white;
    width: ptr(920px);
    height: 95%;
    padding: ptr(79px) ptr(100px) 0;
    position: relative;
    overflow-y: scroll;
    z-index: 2;
    span {
        width: 100%;
    }
    img {
        top: ptr(40px);
        right: ptr(40px);
        position: absolute;
        width: ptr(20px);
        cursor: pointer;
    }
}
@keyframes on {
    from {
        opacity: 0;
        transform: translate3d(0, ptr(200px), 0);
    }
}
.fade-enter-active {
    animation: on 0.3s ease-in-out;
}
.fade-leave-active {
    animation: on 0.3s ease-in-out reverse;
}
@media (max-width: 900px) {
    .mobile {
        display: block;
    }
    .desktop-icon {
        display: none;
    }
    .mobile-icon {
        display: block;
        img {
            width: ptr(40px);
            height: ptr(40px);
        }
    }
    header {
        padding: ptr(8px) ptr(10px);
    }
    .text-info {
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        a:nth-child(3) {
            margin: 0;
            font-size: ptr(14px);
        }
    }
    .get-pay {
        p {
            font-size: ptr(12px);
            display: inline-flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: ptr(20px);
        }
        span {
            margin-top: ptr(6px);
        }
        button {
            height: ptr(60px);
        }
        label {
            font-size: ptr(10px);
            button {
                font-size: ptr(10px);
                height: unset;
            }
        }
    }
    .offer-content {
        width: 100%;
        padding: ptr(10px) ptr(10px) 0;
        img {
            top: ptr(10px);
            right: ptr(10px);
            width: ptr(20px);
        }
    }
    h1 {
        font-size: ptr(36px);
    }
    .pay-info {
        margin-top: ptr(81px);
    }
    h2 {
        margin-bottom: ptr(33px);
        font-size: ptr(20px);
    }
    .hrefs {
        margin: ptr(80px) 0;
    }
    .href-container {
        grid-auto-flow: row;
    }
    .info-container {
        width: ptr(300px);
        height: ptr(828px);
        padding: ptr(48px) 0;
        p {
            font-size: ptr(13px);
            line-height: ptr(18px);
            width: ptr(273px);
        }
    }
    .pay-imgs {
        gap: ptr(20px);
        margin-top: ptr(30px);
        img {
            width: ptr(40px);
        }
    }
    footer {
        margin: ptr(62px) 0;
    }
}
</style>
